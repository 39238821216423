
import getTravelPolicyList from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getTravelPolicyList";
import delTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_delTravelPolicy";


export default {
  name: "evectionPolicy",
  data() {
    return {
      positionList: {}
    };
  },
  created() {
    
  },
  activated () {
    this._getTravelPolicyList();
  },
  methods: {
    addEvectionPolicy() {
      this.$router.push({ name: 'admin-evection-add', query: { type: 'add' } })
    },
    _deletePosition(val) {
      this.$confirm('确定删除此政策吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTravelPolicy({ id: val.id }).then(res => {
          this._getTravelPolicyList()
        })
      }).catch(() => {
      });
    },
    async _getTravelPolicyList() {
      let res = await getTravelPolicyList()
      this.positionList = res.datas
    },
    editPosition(val) {
      this.$router.push({
        name: 'admin-evection-add',
        query: { type: 'edit', detail: JSON.stringify(val) },
        //  params: { detail: val }
      })
    }
  }
}